import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/form.module.css"
import Axios from "axios"

export default class ContactFormComponent extends React.Component {
  state = {
    name: { value: "", error: false, message: "" },
    email: { value: "", error: false, message: "" },
    message: { value: "", error: false, message: "" },
    submitActive: false,
    globalFormError: "", //Non-validation error during submit (e.g. backend unreachable)
    success: false,
  }
  statics = {
    formFields: ["name", "email", "message"],
  }
  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    let stateVal = this.state[name]
    stateVal.value = value
    this.setState({
      [name]: stateVal,
    })
  }
  handleSubmit = (event) => {
    event.preventDefault()
    let submitObj = {}
    submitObj["_method"] = "PUT" // If symfony doesn't get this, it barfs
    submitObj["_locale"] = "de_DE" // For now hardcoded as long as we're german only
    //Reset all errors prior to submission
    this.setState({
      globalFormError: "",
    })
    this.statics.formFields.forEach((key) => {
      let stateVal = this.state[key]
      stateVal.message = ""
      stateVal.error = false
      this.setState({
        [key]: stateVal,
      })
      submitObj[key] = stateVal.value
    })
    //disable the submit button
    this.setState({ submitActive: true })
    Axios.post(event.target.getAttribute("action"), submitObj)
      .then((response) => {
        this.setState({ success: true })
      })
      .catch((error) => {
        let response = error.response
        if (!response.data || !response.data.error_code) {
          //Grave error, we don't even have an error code
          this.setState({
            globalFormError:
              "Unbekannter Fehler bei der Übertragung, bitte versuchen Sie es später noch einmal.",
          })
          return
        }
        if (response.data.error_code === "general") {
          //Grave error, but somewhere in symfony land. We can show a message if we have one
          if (response.data.message) {
            this.setState({
              globalFormError:
                "Fehler bei der Übertragung (" +
                response.data.message +
                "), bitte versuchen Sie es später noch einmal.",
            })
          } else {
            this.setState({
              globalFormError:
                "Unbekannter Fehler bei der Übertragung, bitte versuchen Sie es später noch einmal.",
            })
          }
          return
        }
        if (response.data.error_code === "error_validation") {
          //Some field(s) failed to validate
          response.data.data.forEach((validationError) => {
            let stateVal = this.state[validationError.name]
            stateVal.message = validationError.message
            stateVal.error = true
            this.setState({
              [validationError.name]: stateVal,
            })
          })
          this.setState({
            globalFormError:
              "Einige Angaben konnten nicht validiert werden, bitte korrigieren Sie diese (in rot markiert).",
          })
          return
        }
        //Catch all
        if (response.data.message) {
          this.setState({
            globalFormError:
              "Fehler bei der Übertragung (" +
              response.data.error_code +
              ", " +
              response.data.message +
              "), bitte versuchen Sie es später noch einmal.",
          })
        } else {
          this.setState({
            globalFormError:
              "Unbekannter Fehler bei der Übertragung, bitte versuchen Sie es später noch einmal.",
          })
        }
      })
      .finally(() => {
        this.setState({ submitActive: false })
      })
  }

  render() {
    return (
      <section className={styles.formContainer}>
        <div style={{ display: this.state.success ? "none" : "block" }}>
          <Title title="Nachricht" subtitle="Schreiben" />
          <div className={styles.center}>
            <form
              action={`${process.env.API_BASEURL}/contactform/submit`}
              method="POST"
              className={styles.form}
              onSubmit={this.handleSubmit}
            >
              <div>
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Max Mustermann"
                  className={
                    styles.formControl + (this.state.name.error ? " error" : "")
                  }
                  required
                  value={this.state.name.value}
                  onChange={this.handleInputChange}
                />
                <div
                  className={styles.formElementError}
                  style={{ display: this.state.name.error ? "block" : "none" }}
                >
                  {this.state.name.message}
                </div>
              </div>
              <div>
                <label htmlFor="email">E-Mail*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="email@email.de"
                  className={
                    styles.formControl +
                    (this.state.email.error ? " error" : "")
                  }
                  required
                  value={this.state.email.value}
                  onChange={this.handleInputChange}
                />
                <div
                  className={styles.formElementError}
                  style={{ display: this.state.email.error ? "block" : "none" }}
                >
                  {this.state.email.message}
                </div>
              </div>
              <div>
                <label htmlFor="message">Nachricht*</label>
                <textarea
                  name="message"
                  id="message"
                  rows="10"
                  placeholder="Hallo..."
                  className={
                    styles.formControl +
                    (this.state.message.error ? " error" : "")
                  }
                  required
                  value={this.state.message.value}
                  onChange={this.handleInputChange}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.message.error ? "block" : "none",
                  }}
                >
                  {this.state.message.message}
                </div>
              </div>
              <div className={styles.formGlobalError}>
                {this.state.globalFormError}
              </div>
              <p>
                Wenn Sie die im Kontaktformular eingegebenen Daten durch Klick
                auf den nachfolgenden Button übersenden, erklären Sie sich mit
                der{" "}
                <AniLink fade to="/privacy">
                  Datenschutzerklärung
                </AniLink>{" "}
                auf dieser Website einverstanden.
              </p>
              <div>
                <input
                  type="submit"
                  value="Absenden"
                  className={styles.submit}
                  disabled={this.state.submitActive}
                />
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: this.state.success ? "block" : "none" }}>
          <Title title="Vielen Dank!" />
          <div className={styles.center}>
            <p>
              Ihre Nachricht ist bei uns eingegangen. Wir melden uns in Kürze
              bei Ihnen!
            </p>
          </div>
        </div>
      </section>
    )
  }
}
